html,
body {
  height: 100%;
}

h2 {
  color: #8199a2;
}

h6 {
  margin-top: 20px;
}

.container-fluid {
  padding: 60px;
}

.top-menu {
  font-family: Montserrat;
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.17);
  background: #f9f9f9;
}

.top-menu .email {
  padding-left: 0;
}

.top-menu button,
.top-menu a,
.button-link,
a {
  border: 0;
  background: transparent;
  color: #66b2ce !important;
  font-size: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

.download-button {
  background: #009600;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  margin-top: 20px;
}
.download-button:hover{
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  cursor: pointer;
  border: 0;
}
.top-menu button:hover,
.top-menu a:hover,
.button-link:hover,
a:hover {
  color: gray !important;
  text-decoration: none;
  cursor: pointer;
  border: 0;
}

.top-menu button:active,
.top-menu a:active,
.button-link:active,
.top-menu button:focus,
.top-menu a:focus,
.button-link:focus,
a:focus,
a:active {
  outline: none;
  border-bottom: 1px solid #dcdcdc;
  color: gray !important;
}

.button-link {
  font-size: 1rem;
}

.external-link {
  margin-left: 0;
  padding-left: 0;
  font-size: 1rem;
}

.logo {
  height: 40px;
}

.main-panel {
  color: #626262;
  margin-top: 60px;
  background: #f9f9f9;
}

.main-panel img {
  border-radius: 5px;
  max-width: 800px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.17);
}

.main-panel .download {
  opacity: 0.5;
  margin-right: 10px;
  margin-top: -5px;
  box-shadow: none;
}

.contact-info p {
  margin: 0;
  margin-left: 10px;
  font-size: 20px;
  color: gray;
}

.accordion {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}
h2.acc_trigger {
  padding: 0;
  margin: 0 0 5px 0;
  line-height: 46px;
  font-size: 1.5em;
  font-weight: normal;
  margin-bottom: 0;
  color: #222;
  text-decoration: none;
  display: block;
  padding: 10px;
  text-align: left;
  background-color: var(--lwc-colorBackground, rgb(243, 242, 242));
  color: rgba(36, 45, 67, 1);
  border: 1px solid #dde8db;
  cursor: pointer;
}
.acc_container {
  margin: 0 0 5px;
  padding: 20px;
  overflow: hidden;
  clear: both;
  background: white;
  border: 1px solid #d6d6d6;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: none;
}
.acc_container .block {
  padding: 20px;
}
.roinum {
  text-align: center;
}

@media (max-width: 800px) {
  .main-panel img {
    width: 100%;
  }
  .container-fluid {
    padding: 30px;
  }
}
